import React from 'react';

export default function WordMark() {
  return (
    <svg id="Layer_2" aria-label='Asterism Books homepage' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1340.9 147">
      <g id="Layer_1-2"><path d="m111.07,81.78l-16.82,18.75,23.01,3.67-5.8,19.91-21.65-9.86,3.29,24.55h-22.62l3.48-24.55-21.65,9.86-5.8-19.91,23.2-3.67-16.82-18.75,17.21-12.37,11.99,22.62,11.79-22.62,17.21,12.37Z" fill="#348d85" /><g>
        <path d="m260.91,90h-47.2l-6.8,17.4c-9,23.6-5.6,30.4,12.6,33.4v2.6h-46.8v-2.6c13.2-3.6,20.4-11.4,28.8-33L244.11,0h8.4l44.8,121.6c5,13.6,8,17,23.4,19.2v2.6h-65.6v-2.6c19-1,21.8-5.2,17.8-16.4l-12-34.4Zm-1.4-4l-20.8-59h-.8l-22.8,59h44.4Z" fill="#121212" />
        <path d="m341.91,97h2.8c12,26.6,26.2,45.2,47.6,45.2,19.6,0,29.4-13.2,29.4-25.8,0-17.6-12-24.6-36.8-33.2-20-7-40.8-13.4-40.8-39.2S364.31,1.2,391.31,1.2c11.8,0,22.8,3.4,30,6.8L428.51,0h2.4v44.6h-2.8c-11-26.4-23.8-39.6-40-39.6-13.6,0-26,8.8-26,24.6,0,17.2,13,23.6,36,31.2,19,6.4,39.4,13.4,39.4,39.4,0,22-13,46-47.6,46-11.4,0-24.8-4-33.6-9l-12,9.8h-2.4v-50Z" fill="#121212" />
        <path d="m490.31,140.8c17.8-.6,23.8-5,23.8-13.2V7.8h-7.8c-14.8,0-27.6,13.6-40.2,39.8l-2.8-.2,1.2-43.4h122.6l1.2,43.4-2.8.2c-12.4-25.2-25.2-39.8-40.4-39.8h-7.8v119.8c0,8.2,6,12.6,23.8,13.2v2.6h-70.8v-2.6Z" fill="#121212" />
        <path d="m617.11,140.8c17.4-.6,21.8-5,21.8-13.2V19.6c0-8-4.4-12.6-21.8-13.2v-2.4h116l.6,45-2.8.4c-11.6-28.4-20.8-41.6-44.6-41.6h-24v61.4h10.8c16.6,0,25.4-6.2,30.8-27.4h2.6v58.6h-2.6c-5.4-21.2-14.2-27.4-30.8-27.4h-10.8v66.6h23.6c28,0,38.6-13.4,50.8-44l2.8.2-1,47.6h-121.4v-2.6Z" fill="#121212" />
        <path d="m816.51,127.6c0,8.6,4.2,12.6,21,13.2v2.6h-66.2v-2.6c17.4-.6,21.8-5,21.8-13.2V19.6c0-8-4.4-12.6-21.8-13.2v-2.4h63c41.2,0,56.8,15,56.8,33.8,0,17.2-11.6,30.6-42.4,35.2v.6c29.6,1,36.6,14.2,39.4,34.4l1.4,11.4c2.8,17.8,6,20.2,23.2,20.4v2.4c-6.8,1.6-14,2.2-20.6,2.2-21.6,0-25.6-7.4-27.6-25.4l-2-18.4c-2.2-20.2-7.6-25.4-30.8-25.4h-15.2v52.4Zm16.8-56.2c26.8,0,32.4-11.4,32.4-32.6,0-19-8-31-35-31h-14.2v63.6h16.8Z" fill="#121212" />
        <path d="m930.51,140.8c18.2-.6,22.4-5,22.4-13.2V19.6c0-8-4.2-12.6-22.4-13.2v-2.4h68v2.4c-18.2.6-22.4,5.2-22.4,13.2v108c0,8.2,4.2,12.6,22.4,13.2v2.6h-68v-2.6Z" fill="#121212" />
        <path d="m1032.51,97h2.8c12,26.6,26.2,45.2,47.6,45.2,19.6,0,29.4-13.2,29.4-25.8,0-17.6-12-24.6-36.8-33.2-20-7-40.8-13.4-40.8-39.2s20.2-42.8,47.2-42.8c11.8,0,22.8,3.4,30,6.8L1119.1,0h2.4v44.6h-2.8c-11-26.4-23.8-39.6-40-39.6-13.6,0-26,8.8-26,24.6,0,17.2,13,23.6,36,31.2,19,6.4,39.4,13.4,39.4,39.4,0,22-13,46-47.6,46-11.4,0-24.8-4-33.6-9l-12,9.8h-2.4v-50Z" fill="#121212" />
        <path d="m1159.1,140.8c18.8-3,24.2-11.2,24.2-30.8V28.8c0-12.2-7-22-24.2-22.4v-2.4h50.2l43.6,107.8h.8l41.2-107.8h46v2.4c-17.2.6-21.8,5.2-21.8,13.2v108c0,8.2,4.6,12.6,21.8,13.2v2.6h-65.2v-2.6c17-.6,20.2-4.6,20.2-13.2V17.4h-1l-48.4,126.2h-4.6l-52.6-126.6h-1v93c0,19.6,5,28,25.2,30.8v2.6h-54.4v-2.6Z" fill="#121212" /></g>
        <path d="m159.31,12.37l-16.82,18.75,23.01,3.67-5.8,19.91-21.65-9.86,3.29,24.55h-22.62l3.48-24.55-21.65,9.86-5.8-19.91,23.2-3.67-16.82-18.75L118.32,0l11.99,22.62L142.1,0l17.21,12.37Z" fill="#348d85" />
        <path d="m64.58,12.37l-16.82,18.75,23.01,3.67-5.8,19.91-21.65-9.86,3.29,24.55h-22.62l3.48-24.55-21.65,9.86L0,34.8l23.2-3.67L6.38,12.37,23.59,0l11.99,22.62L47.37,0l17.21,12.37Z" fill="#348d85" /></g>
    </svg>
  )
}