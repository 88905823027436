import React from 'react';
import WordMark from '../icons/wordmark';
import styles from './header.module.css';

export default function Header() {
  return (
    <div className={`${styles.header}`}>
      <div className={`${styles.logo}`}>
        <WordMark />
      </div>
    </div>
  )
}