import React from 'react';
import styles from './message.module.css';

export default function Message({ message, clearMessage }) {

  if (message.temp) {
    setTimeout(() => {
      clearMessage()
    }, 3500)
  }

  if (!message.text) {
    return (<></>)
  }

  return (
    <div className={`${styles.displayMessage} ${!message.temp && styles.messageGrid} ${message.type === 'success' ? styles.success : message.type === 'error' ? styles.error : styles.info}`}>
      <div className={styles.messageInfo}>
        <div className={styles.messageLabel}>{message.label ? message.label : message.type}</div>
        <div className={styles.messageText} dangerouslySetInnerHTML={{ __html: message.text }}></div>
      </div>
      {!message.temp && <button onClick={clearMessage}>X</button>}
    </div>
  )
}