import logo from './logo.svg';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import './App.css';
import { RecoilRoot } from 'recoil';
import Header from './components/header'
import TitleTester from './components/titleTester';

document.title = 'Asterism Books | Data Feeds';

function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <Header />
      </div>
      <div className="BodyContainer">
        <div>
          <p><strong>Welcome!</strong> This website aims to give you everything you need to generate and download ONIX feeds from Asterism Books. Below, you can pull records for individual titles, generate a delta feed for all changes after a given date, or pull everything (please be mindful of rate limits for expensive calls). You can test the feeds below, as well as download the results data. You can also copy the URL to query the feed directly from within your own application.</p>
        </div>
        <TitleTester />
      </div>
    </RecoilRoot>
  );
}

export default App;
