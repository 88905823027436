import React, { useState } from 'react';
import XMLViewer from 'react-xml-viewer';
import styles from './titletester.module.css';
import Message from '../message/index.js';

export default function TitleTester() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState('');
  const [id, setId] = useState('');
  const [version, setVersion] = useState('3.1');
  const [startDate, setStartDate] = useState('2024-08-16');
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState({});

  let urlBase = window.location.host.includes('localhost') ? `http://localhost:3006/` : 'https://feeds.asterismbooks.com/api/'

  const getResults = async () => {
    setLoading(true);
    setResults('');
    setUrl('');
    let data = await fetch(`${urlBase}onix/${version}?product=${id}${startDate ? `&since=${startDate}` : ''}`);
    setUrl(`${urlBase}onix/${version}?product=${id}${startDate ? `&since=${startDate}` : ''}`);
    let text = await data.text()
    setResults(text);
    setLoading(false);
  }

  const customTheme = {
    "attributeKeyColor": "#1c4642",
    "tagColor": "#348d85"
  }

  const download = () => {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(results);
    hiddenElement.target = '_blank';
    hiddenElement.download = `asterism-onix-${version}-${id ? `${id}-` : ''}${new Date().toISOString()}.xml`;
    hiddenElement.click();
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    setMessage({ type: 'success', text: 'URL successfully copied to your clipboard', temp: true })
  }

  return (
    <div className={styles.appContainer}>
      <div className={styles.testControls}>
        <div className={styles.formElement}>
          <div className={styles.formLabel}>Product ID</div>
          <input value={id} onChange={(e) => { setId(e.target.value.replace(/–|-|\s/g, '')) }} />
          <div className={styles.helpText}>Leave blank to pull all matching titles.</div>
        </div>
        <div className={styles.formElement}>
          <div className={styles.formLabel}>Start Date</div>
          <input type='date' value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
          <div className={styles.helpText}>If a date is selected, the feed will only show files modified after the date given.</div>

        </div>
        <div className={styles.formElement}>
          <div className={styles.formLabel}>ONIX Version</div>
          <select value={version} onChange={(e) => { setVersion(e.target.value) }}>
            <option value='3.1'>ONIX v3.1</option>
            <option value='3.0'>ONIX v3.0</option>
          </select>
          <p>We support ONIX versions 3.0 and 3.1, currently. If you have other needs, please get in touch at info [a] asterismbooks.com!</p>
        </div>
        <div className={styles.formElement}>
          <div className={styles.copyControl}>
            <input diabled={true} value={url} />
            <button disabled={!url} onClick={copyUrl}>Copy</button>
          </div>
        </div>
        <div className={styles.inputs}>
          <button onClick={getResults}>Test</button>
          <button disabled={!results} onClick={download}>Download</button>
        </div>
      </div>

      <div className={styles.resultsViewer}>
        <XMLViewer xml={results} theme={customTheme} />
        {loading && <Loader />}
      </div>
      <Message message={message} clearMessage={() => { setMessage({}) }} />
    </div >
  )
}

function Loader() {
  return (<svg className={styles.loader} version="1.1" id="L4" x="0px" y="0px"
    viewBox="0 0 100 100" enable-background="new 0 0 0 0">
    <circle fill="#348d85" stroke="none" cx="6" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1" />
    </circle>
    <circle fill="#348d85" stroke="none" cx="26" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2" />
    </circle>
    <circle fill="#348d85" stroke="none" cx="46" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3" />
    </circle>
  </svg>)
}